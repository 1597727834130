import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import Alert from '../Alert';
import Privacy from '../Privacy';

import axios from 'axios';
import { getLocals } from '../../utils/locals';

import logo from '../../assets/images/cartoober_logo.svg';

import './index.css';

const ContactForm = ({
  visible,
  background,
  onClose = () => null,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [displayPrivacy, setDisplayPrivacy] = useState(false);
  const locals = getLocals('en');

  const onSubmit = () => {
    if (name.trim().length === 0) {
      setError(locals.empty_name);
      setAlertType('error');
      setIsVisible(true);
      return;
    }

    if (email.trim().length === 0) {
      setError(locals.empty_email);
      setAlertType('error');
      setIsVisible(true);
      return;
    }
    // eslint-disable-next-line
    let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      setAlertType('error');
      setError(locals.invalid_email);
      setIsVisible(true);
      return;
    }

    if (message.trim().length === 0) {
      setError(locals.empty_message);
      setAlertType('error');
      setIsVisible(true);
      return;
    }
    setIsLoading(true);
    axios.post('https://hoppia.herokuapp.com/cartoober-contact', { name, email, message })
      .then(() => {
        setAlertType('success');
        setError(locals.success_message);
        setIsVisible(true);
        setIsLoading(false);
        setEmail('');
        setName('');
        setMessage('');
      })
      .catch(() => {
        setError(locals.error_message);
        setAlertType('error');
        setIsVisible(true);
        setIsLoading(false);
      });
  };


  useEffect(() => {
    axios.get('https://hoppia.herokuapp.com/hello');
  }, []);

  const styles = background ? { background: `url(${background})` } : {};
  if (!visible) return null;

  if (displayPrivacy) return <Modal>
    <div className='contact_form page' style={styles}>
      <Privacy onClose={() => setDisplayPrivacy(false)} />
    </div>
  </Modal>

  return <Modal>
    <div className='contact_form page' style={styles}>
      <div className='login_modal'>
        <div className='login_modal__header'>
          <img className='logo' src={logo} alt="Logo cartoober" />
          <span onClick={onClose} className='icon icon-close btn' />
        </div>


        <div className='contact_form__us'>
          <h4 className='font_azo'>{locals.contact_us}</h4>
          <p>hello@cartoober.com</p>
        </div>

        <h4 className='font_azo'>{locals.message_us}</h4>

        <p>{locals.contact_form_desc}</p>

        <input
          type='text'
          placeholder={locals.input_name}
          value={name}
          onChange={e => setName(e.target.value)}
        />

        <input
          type='email'
          placeholder={locals.input_email}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />

        <input
          type='text'
          placeholder={locals.input_message}
          value={message}
          onChange={e => setMessage(e.target.value)}
        />

        <div>
          {
            !isLoading
              ? <span onClick={onSubmit} className='btn send_btn'>{locals.send_btn}</span>
              : <span className='btn send_btn'>{locals.loading_message}</span>
          }
        </div>

        <p className='text_mini'>{locals.made_with_love}</p>
        <p onClick={() => setDisplayPrivacy(true)} className='text_mini btn'>{locals.privacy_policy}</p>
      </div>
    </div>
    <Alert
      type={alertType}
      visible={isVisible}
      onHide={() => {
        setIsVisible(false);
        setAlertType('');
      }}
    >
      <span>{error}</span>
    </Alert>
  </Modal>;
};

export default ContactForm;