const initialState = {
  uid: '',
  email: '',
  displayName: '',
  photoURL: '',
  providerData: [],
  streams: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER': return { ...state, ...action.user };
    case 'SET_STREAMS': return { ...state, streams: action.streams };
    default: return state;
  }
};

export default userReducer;