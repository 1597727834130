import { Route, Routes } from 'react-router-dom';
import { getUrl } from './utils/routes';

import { AuthProvider } from './providers/AuthProvider';
import { SocketProvider } from './providers/SocketProvider';

import Auth from './pages/Auth';
import Contact from './pages/Contact';
import Home from './pages/Home';
import NewStream from './pages/NewStream';
import Stream from './pages/Stream';
import PrivateRoute from './baseComponents/PrivateRoute';
import Privacy from './pages/Privacy';
import PasswordRecovery from './pages/PasswordRecovery';


const privateRoute = Component => <PrivateRoute><Component /></PrivateRoute>;

const App = () => {
  return <div className='main'>
    <AuthProvider>
      <SocketProvider>
        <Routes>
          <Route path={getUrl('privacy')} element={<Privacy />} />
          <Route path={`${getUrl('pwdReset')}/:id`} element={<PasswordRecovery />} />
          <Route path={getUrl('auth')} element={<Auth />} />
          <Route path={getUrl('contact')} element={<Contact />} />
          <Route path={getUrl('home')} element={<Home />} />
          <Route path={getUrl('stream')}>
            <Route path='new' element={privateRoute(NewStream)} />
            <Route path=':id' element={privateRoute(Stream)} />
          </Route>
          <Route path='/*' element={<div>Hola</div>} />
        </Routes>
      </SocketProvider>
    </AuthProvider>
  </div>;
}

export default App;
