import React, { useEffect } from 'react';

import './index.css';

const Alert = ({
  type = 'success',
  autoHide = true,
  visible = false,
  children,
  onHide = () => null
}) => {


  useEffect(() => {
    let timeout = null;
    if (visible && autoHide) timeout = setTimeout(onHide, 3000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return <div
    className={`alert ${type} ${visible ? 'visible' : ''}`}>
    {children}
  </div>;
};

export default Alert;