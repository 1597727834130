import React, { useState, useEffect } from 'react';
import AvatarSlider from '../../../baseComponents/AvatarSlider';

const Backgrounds = ({
  backgrounds = [],
  avatar = null,
  current = null,
  onChange = () => null,
}) => {
  const [avatares, setAvatares] = useState([]);

  useEffect(() => {
    let avatares = backgrounds.map(background => ({
      ...background,
      avatar: avatar ? avatar.avatar : null
    }));
    avatares = [avatar, ...avatares];
    setAvatares(avatares);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  return <div>
    <AvatarSlider
      mode='grid'
      type='square'
      showAvatar={false}
      showBackground={true}
      isLoading={false}
      avatares={avatares}
      avatarSelected={current.id}
      setAvatarSelected={onChange}
    />
  </div>;
};

export default Backgrounds;