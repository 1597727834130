import { fbDatabase } from '../services/firebase';

export const getAvatares = () => {
  return new Promise((resolve, reject) => {
    fbDatabase.ref('avatars')
      .once('value', snapshot => {
        let avatars = snapshot.val();
        if (avatars) {
          avatars = Object.values(avatars);
          resolve(avatars);
        }
        else reject('No avatars found');
      })
      .catch(error => reject('Error getting avatars'));
  });
};

export const getAvatar = id => {
  return new Promise((resolve, reject) => {
    fbDatabase.ref('avatars')
      .child(id)
      .once('value', snapshot => {
        let avatar = snapshot.val();
        if (avatar) resolve(avatar);
        else reject('No avatar found');
      })
      .catch(error => reject('Error getting avatar'));
  });
};