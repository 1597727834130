import axios from 'axios';
import crypto from 'crypto-js';

let service = axios.create({
  baseURL: 'https://cartoober-back.herokuapp.com',
});

export const startService = () => {
  return service.get('/');
};

export const getUser = email => {
  return service.post('/api/user', { email })
    .then(result => result.data);
};

export const changePassword = (uid, password, email, key) => {
  let id = crypto.AES.encrypt(uid, process.env.REACT_APP_FIREBASE_KEY).toString();
  let newPwd = crypto.AES.encrypt(password, process.env.REACT_APP_FIREBASE_KEY).toString();
  let mail = crypto.AES.encrypt(email, process.env.REACT_APP_FIREBASE_KEY).toString();
  let keyCode = crypto.AES.encrypt(key, process.env.REACT_APP_FIREBASE_KEY).toString();
  return service.post('/api/user/pwd', { uid: id, pwd: newPwd, email: mail, keyCode })
    .then(result => result.data);
};