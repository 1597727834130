import hero_texts from './hero_texts';
import home_texts from './home_texts';
import login_texts from './login_texts';
import error_texts from './error_texts';
import success_texts from './success_texts';

const langs = {
  en: {
    ...hero_texts.en,
    ...home_texts.en,
    ...login_texts.en,
    ...error_texts.en,
    ...success_texts.en,
    'new_stream': 'Create a streaming',
    'title_input_stream': 'Choose your title',
    'title_placeholder_stream': 'My awesome stream',
    'background_title_stream': 'Choose your background',
    'avatar_title_stream': 'Choose your avatar',
    'loading': 'Loading',
    'stream': 'Stream',
    'contact': 'Contact',
    'stream_description': `Stream as an animated avatar from your browser.`,
    'title_input': 'TITLE',
    'title_placeholder': 'Enter name',
    'avatar_title': 'AVATAR',
    'background_title': 'BACKGROUND',
    'input_name': 'Name',
    'input_email': 'E-mail',
    'input_message': 'Message',
    'made_with_love': 'Made with love from Mexico City',
    'privacy_policy': 'Privacy Policy',
    'loading_message': 'Sending message...',
    'contact_form_desc': 'We love to hear from you. Give us your feedback!.',
    'go_login': 'Go to login',
    'unexpected_error': 'Unexpected error',
  },
  es: {
    ...hero_texts.es,
    ...home_texts.es,
    ...login_texts.es,
    ...error_texts.es,
    ...success_texts.es,
    'new_stream': 'Crear un streaming',
    'title_input_stream': 'Elige tu título',
    'title_placeholder_stream': 'Mi increíble stream',
    'background_title_stream': 'Elige tu fondo',
    'avatar_title_stream': 'Elige tu avatar',
    'loading': 'Cargando',
    'stream': 'Transmitir',
    'contact': 'Contacto',
    'stream_description': `Stream as an animated avatar from your browser.`,
    'title_input': 'TITLE',
    'title_placeholder': 'Enter name',
    'avatar_title': 'AVATAR',
    'background_title': 'BACKGROUND',
    'input_name': 'NAME',
    'input_email': 'EMAIL',
    'input_message': 'MESSAGE',
    'made_with_love': 'Hecho con amor desde ciudad México',
    'privacy_policy': 'Política de privacidad',
    'loading_message': 'Enviando mensaje...',
    'contact_form_desc': 'We love to hear from you. Give us your feedback!.',
    'go_login': 'Go to login',
    'unexpected_error': 'Unexpected error',
  },
};


export const getLocals = (lang = 'en') => {
  return {
    ...langs[lang],
  };
};
