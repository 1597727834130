import React, { useState, useEffect } from 'react';
import Loader from '../../baseComponents/Loader';

import { getLocals } from '../../utils/locals';
import { useNavigate, useParams } from 'react-router-dom';
import { getUrl } from '../../utils/routes';
import { fbDatabase } from '../../services/firebase';
import { changePassword, startService } from '../../services/heroku';

import logo from '../../assets/images/cartoober_logo.svg';

const PasswordRecovery = () => {
  const params = useParams();
  const { id } = params;
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [uid, setUid] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const onHomeHandler = () => navigate(getUrl('home'));
  const onPrivacyHandler = () => navigate(getUrl('privacy'));
  const onLoginHandler = () => navigate(getUrl('auth'));
  const locals = getLocals('en');
  const onSubmitHandler = () => {
    if (!password.trim().length) return setError(locals.empty_password);
    if (password.trim().length < 6) return setError(locals.password_length);
    setIsSending(true);
    setError('');
    changePassword(uid, password, email, id)
      .then(response => {
        if (response.code === 200) {
          setIsSending(false);
          setSuccess(true);
        } else {
          setIsSending(false);
          setError(locals.unexpected_error);
        }
      })
      .catch(err => {
        setIsSending(false);
        setError(locals.unexpected_error);
      });
  };

  useEffect(() => {
    fbDatabase.ref(`/pwdReset/${id}`).once('value')
      .then(snapshot => {
        let user = snapshot.val();
        if (!user) return navigate(getUrl('home'));

        let timestamp = user.timestamp - Date.now();
        if (timestamp < 0) return navigate(getUrl('home'));
        setUid(user.uid);
        setEmail(user.email);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
    startService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading) return <Loader />;

  if (success) return <div id='auth' className='page'>
    <div>
      <div className='auth__header'>
        <img onClick={onHomeHandler} className='logo btn' src={logo} alt='Logo cartoober' />
        <h1>Password recovery</h1>
        <div />
      </div>
      <div className='auth__content'>
        <div className='login_form'>
          <div className='login_form__fields'>
            <h3 className='text_center'>{locals.password_success}</h3>
            <div className='login_form__buttons'>
              <button onClick={onLoginHandler} className='btn login_form__btn'>
                {locals.go_login}
              </button>
            </div>
            <span className='error'>{error}</span>
          </div>
        </div>
      </div>
    </div>
    <p className='privacy btn' onClick={onPrivacyHandler}>{locals.privacy_policy}</p>
  </div>

  return <div id='auth' className='page'>
    <div>
      <div className='auth__header'>
        <img onClick={onHomeHandler} className='logo btn' src={logo} alt='Logo cartoober' />
        <h1>Password recovery</h1>
        <div />
      </div>
      <div className='auth__content'>
        <div className='login_form'>
          <div className='login_form__fields'>
            <div className='login_form__field'>
              <label className='' htmlFor='password'>Password</label>
              <input id='password' name='password' type='password'
                value={password} onChange={e => setPassword(e.target.value)} />
            </div>
            <div className='login_form__buttons'>
              {
                isSending
                  ? <button className='login_form__btn'>
                    Loading...
                  </button>
                  : <button onClick={onSubmitHandler} className='btn login_form__btn'>
                    Reset password
                  </button>
              }
            </div>
            <span className='error'>{error}</span>
            <div className='login_form__options'>
              <p>
                Change your mind? <b className='btn' onClick={onLoginHandler}>Login</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p className='privacy btn' onClick={onPrivacyHandler}>{locals.privacy_policy}</p>
  </div>;
};

export default PasswordRecovery;