import React, { useContext } from 'react';
import LoginForm from '../LoginForm';
import { AuthContext } from '../../providers/AuthProvider';
import Embed from '../../baseComponents/Embed'

import { connect } from 'react-redux';
import { getLocals } from '../../utils/locals';
import './index.css'

const Hero = props => {
  const { user } = useContext(AuthContext);
  const { lang } = props.app;
  const locals = getLocals(lang);

  return <div className='hero'>
    <div className='hero__info'>
      <div className='hero__info_title'>
        <h2 dangerouslySetInnerHTML={{ __html: locals.hero_title_1 }} />
        <h2>{locals.hero_title_2}</h2>
      </div>
      <div className="video_container">
        <Embed>
          <iframe
            src="https://player.vimeo.com/video/692450230?autoplay=0&loop=1&autopause=0&title=0&byline=0&portrait=0&sidedock=0"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Cartoober" />
        </Embed>
      </div>
    </div>
    <div className='hero__login'>
      {
        Boolean(user)
          ? <div className='empty_space' />
          : <LoginForm />
      }
    </div>
  </div>;
}

const mapStateToProps = state => ({
  app: state.app,
});
export default connect(mapStateToProps)(Hero);