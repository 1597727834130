import React, { useState, useEffect, useRef } from 'react';
import StreamCanvas from './components/StreamCanvas';
import Settings from './components/Settings';
import Header from '../../baseComponents/Header';
import Alert from '../../baseComponents/Alert';
import Loader from '../../baseComponents/Loader';

import { useNavigate, useParams } from 'react-router-dom';
import { getUrl } from '../../utils/routes';
import { getAvatares } from '../../services/avatares';
import { getBackgrounds } from '../../services/backgrounds';
import { getLocals } from './locals';
import { fbAuth, fbDatabase } from '../../services/firebase';
import { connect } from 'react-redux';

import feedbackLogo from '../../assets/images/icons/personaje_rostro.png';
import BottomSetting from './components/BottomSetting';
import useWindowSize from '../../utils/useWindowsSize';
import StreamRecord from './components/StreamRecord';

import './index.css';

function useForceUpdate() {
  console.log('useForceUpdate');
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const Stream = props => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [title, setTitle] = useState('Prueba');
  const [avatar, setAvatar] = useState(null);
  const [avatares, setAvatares] = useState([]);
  const [background, setBackground] = useState(null);
  const [backgrounds, setBackgrounds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showTitle, setShowTitle] = useState(true);
  const [enableCamera, setEnableCamera] = useState(true);
  const [enableAudio, setEnableAudio] = useState(true);
  const [currentTab, setCurrentTab] = useState(window.innerWidth > 769 ? 'background' : null);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [error, setError] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [errorAutoHide, setErrorAutoHide] = useState(true);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const [scale, setScale] = useState([0, 0]);
  const { uid } = props.user;

  const forceUpdate = useForceUpdate();
  const canvasRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const canvasSize = useRef({ width: 100, height: 100 });

  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  let locals = getLocals('en');
  useWindowSize();

  useEffect(() => {
    setSettingsVisible(window.innerWidth > 769);
    document.getElementById('root').classList.add('stream__menu_active');
  }, [])

  const onScale = (e) => {
    setScale([scale[0] + e, e])
  }

  const onLogoutHandler = () => {
    fbAuth.signOut()
      .then(() => {
        navigate(getUrl('home'));
      })
  };

  const onCameraHandler = () => {
    setEnableCamera(!enableCamera);
  };

  const onAudioHandler = () => {
    setEnableAudio(!enableAudio);
  };

  const onFullScreenHandler = () => {
    const container = document.getElementById('stream');
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
    canvasSize.current = { width: container.offsetWidth, height: container.offsetHeight };
    if (fullscreenElement) {
      const exitFullscreen = document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen;
      exitFullscreen.call(document);
      forceUpdate();
    } else {
      const fullScreen = container.requestFullscreen || container.mozRequestFullScreen || container.webkitRequestFullscreen;
      fullScreen.call(container);
    }
  };
  const onAvatarChangeHandler = avatar => {
    const { id } = params;
    setAvatar(avatar);
    localStorage.setItem(id, JSON.stringify({
      title,
      avatar,
      background,
      streamId: id,
    }));
  };
  const onBackgroundChangeHandler = background => {
    const { id } = params;
    setBackground(background);
    localStorage.setItem(id, JSON.stringify({
      title,
      avatar,
      background,
      streamId: id,
    }));
  };

  const onTitleChange = title => {
    const { id } = params;
    setTitle(title);
    localStorage.setItem(id, JSON.stringify({
      title,
      avatar,
      background,
      streamId: id,
    }));
  };

  const onClickHandler = tab => {
    setCurrentTab(tab);
    setSettingsVisible(true);
    document.getElementById('root').classList.add('stream__menu_active');
  }

  const requestUserMedia = async () => {
    try {
      if (navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        setEnableCamera(true);
        setEnableAudio(true);
        setIsLoading(false);
      } else {
        setErrorAutoHide(false);
        setIsErrorVisible(true);
        setAlertType('error');
        setError('Your browser does not support getUserMedia');
        setIsLoading(false);
      }
    } catch (error) {
      setErrorAutoHide(false);
      setEnableCamera(false);
      setEnableAudio(false);
      setIsLoading(false);
      setIsErrorVisible(true);
      setAlertType('error');
      setError(`Camera: ${error.message}`);
    }
  };

  const loadResources = async () => {
    try {
      const avatares = await getAvatares();
      const backgrounds = await getBackgrounds();
      setAvatares(avatares);
      setBackgrounds(backgrounds);
      requestUserMedia();
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fbDatabase.ref(`users/${uid}/streams/${id}`).on('value', snapshot => {
      const stream = snapshot.val();
      if (!stream) return navigate(getUrl('newStream'));
      if (isLoading) loadResources();
      setTitle(stream.title);
      setAvatar(stream.avatar);
      setBackground(stream.background);
    });
  }, [id, isLoading]);


  useEffect(() => {
    const prefixes = ['', 'moz', 'webkit', 'ms'];
    const onFullScreenChange = () => {
      const root = document.getElementById('root');

      (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement)
        ? root.classList.add('fullscreen')
        : root.classList.remove('fullscreen');

      forceUpdate();
    };
    prefixes.forEach(prefix => window.addEventListener(`${prefix}fullscreenchange`, onFullScreenChange.bind(this)));
    return () => {
      prefixes.forEach(prefix => window.removeEventListener(`${prefix}fullscreenchange`, onFullScreenChange.bind(this)));
    }
  }, []);

  if (isLoading) return <Loader />;

  if (!avatar) return null;

  let stream_live_height;
  let stream_live_width;

  if (document.querySelector('.stream__live') && (window.innerWidth > 769)) {
    let container = document.querySelector('.stream__live')
    stream_live_height = container.clientWidth * 1080 / 1920;

    if (stream_live_height > (window.innerHeight - 151.2)) {
      stream_live_height = window.innerHeight - 151.2
    }

    stream_live_width = stream_live_height * 1920 / 1080;

    if ((stream_live_width + document.querySelector('#stream .stream__settings.login_modal').clientWidth + 16.8) > container.clientWidth) {
      stream_live_width = container.clientWidth - document.querySelector('#stream .stream__settings.login_modal').clientWidth - 22.8;
      stream_live_height = stream_live_width * 1080 / 1920;
    }
  }

  console.log('currentTab', currentTab);

  return <div id='stream' className='page'>
    <Alert
      autoHide={errorAutoHide}
      type={alertType}
      visible={isErrorVisible}
      onHide={() => {
        setIsErrorVisible(false);
        setAlertType('');
      }}
    >
      <span>{error}</span>
    </Alert>
    <Header
      showBurgerButton={false}
      background={background.background}
      openForm={isFeedbackVisible}
      onClick={(feedback) => setIsFeedbackVisible(feedback)}
    />
    <section className='stream_player'>
      <div className='stream__live' style={{ height: `calc(${stream_live_height}px + 5rem)`, gridTemplateColumns: `${stream_live_width}px max(min(20rem, 30%), 15rem)` }} >
        <div className='stream_container'>
          <div
            style={{
              width: stream_live_width
            }}>
            <div id='stream__live_container' ref={canvasRef} className='stream__live_container' style={{ transform: window.innerWidth > 769 ? `scale(${stream_live_height / 1080})` : '' }}>
              <div ref={canvasContainerRef}>
                <StreamCanvas
                  enableAudio={enableAudio}
                  enableCamera={enableCamera}
                  showTitle={showTitle}
                  avatar={avatar}
                  background={background.background}
                  title={title}
                  onLoadingModel={setIsModelLoaded}
                  isModelLoaded={isModelLoaded}
                  scale={scale}
                />
              </div>
            </div>
          </div>
          {(window.innerWidth > 769)
            && <BottomSetting
              onCameraHandler={onCameraHandler}
              enableCamera={enableCamera}
              enableAudio={enableAudio}
              onAudioHandler={onAudioHandler}
              onFullScreenHandler={onFullScreenHandler}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              onScale={onScale}
              streamTitle={title}
              setCurrentTab={() => onClickHandler('videoList')} />}
        </div>
        <Settings
          onFullScreenHandler={onFullScreenHandler}
          isModelLoaded={isModelLoaded}
          visible={settingsVisible}
          activeElement={currentTab}
          onClose={() => setSettingsVisible(false)}
          locals={locals}
          showTitle={showTitle}
          title={title}
          onShowTitleChange={setShowTitle}
          onTitleChange={onTitleChange}
          avatares={avatares}
          currentAvatar={avatar}
          onAvatarChange={onAvatarChangeHandler}
          backgrounds={backgrounds}
          currentBackground={background}
          onBackgroundChange={onBackgroundChangeHandler}
          setActive={onClickHandler}
          active={currentTab}
        />
      </div>
    </section>
    <aside className='stream__menu'>
      <div className='stream__menu_container'>
        <div className='stream__menu_container_buttons'>
          <span onClick={() => onClickHandler('title')} className='icon icon-title-btn btn hide_fs' />
          <span onClick={() => onClickHandler('avatar')} className='icon icon-avatar-btn btn hide_fs' />
          <span onClick={() => onClickHandler('background')} className='icon icon-image-btn btn hide_fs' />
          <span onClick={() => onClickHandler('videoList')} className='icon icon-film btn hide_fs' />
          <StreamRecord enableAudio={enableAudio} title={title} mobile={true} isPlaying={isPlaying} setIsPlaying={setIsPlaying} setCurrentTab={() => onClickHandler('videoList')} />
        </div>
        <span onClick={onFullScreenHandler} className='icon icon-fullscreen btn' />
        <span onClick={onLogoutHandler} className='icon icon-sign-out btn hide_fs' />
      </div>
    </aside>
    <div className='stream__feedback'>
      <div onClick={() => setIsFeedbackVisible(!isFeedbackVisible)} className='stream__feedback_text btn'>
        <h2 className='font_azo'>{locals.feedback_one}</h2>
        <p>{locals.feedback_two}</p>
      </div>
      <div onClick={() => setIsFeedbackVisible(!isFeedbackVisible)} className='login_modal stream__feedback_logo btn'>
        <img src={feedbackLogo} alt='cartoober' />
      </div>
    </div>
  </div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Stream);