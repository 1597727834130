const lang = {
  en: {
    'login_google': 'Continue with Google',
    'login_facebook': 'Continue with Facebook',
    'or': 'or',
    'email': 'E-mail',
    'password': 'Password',
    'signup': 'Sign up',
    'login': 'Login',
    'new_to_cartoober': 'New to cartoober?',
    'signup_link': 'Sign up',
    'forgot_password': 'Forgot your password?',
    'already_have_account': 'Already have an account?',
    'login_link': 'Log in',
    'change_password': 'Change password',
    'password_email_sent': 'An email has been sent to your account with instructions to reset your password.',
    'create_account': 'Create your account',
    'recover_password': 'Recover your account',
    'welecome_back': 'Welcome back',
    'message': 'Message',
    'send': 'Send',
    'message_us': 'MESSAGE US',
    'contact_us': 'CONTACT US',
    'mexico_city': 'Mexico City',
  },
  es: {
    'login_google': 'Continuar con Google',
    'login_facebook': 'Continuar con Facebook',
    'or': 'o',
    'email': 'Correo electrónico',
    'password': 'Contraseña',
    'signup': 'Regístrate',
    'login': 'Iniciar sesión',
    'new_to_cartoober': 'Nuevo en cartoober?',
    'signup_link': 'Regístrate',
    'forgot_password': '¿Olvidaste tu contraseña?',
    'already_have_account': '¿Ya tienes una cuenta?',
    'login_link': 'Iniciar sesión',
    'change_password': 'Cambiar contraseña',
    'password_email_sent': 'Se ha enviado un correo electrónico a su cuenta con las instrucciones para restablecer su contraseña.',
    'create_account': 'Crear cuenta',
    'recover_password': 'Recuperar cuenta',
    'welecome_back': 'Bienvenido de nuevo',
    'message': 'Mensaje',
    'send': 'Enviar',
    'message_us': 'MENSAJEANOS',
    'contact_us': 'CONTÁCTANOS',
    'mexico_city': 'Ciudad de México',
  },
};

export default lang;