import React, { useState } from 'react'
import StreamRecord from './StreamRecord'

export default function BottomSetting({
	onFullScreenHandler,
	onScale,
	streamTitle,
	enableCamera,
	onCameraHandler = () => null,
	enableAudio,
	onAudioHandler = () => null,
	setCurrentTab = () => null,
	isPlaying,
	setIsPlaying = () => null,
}) {


	return <div className='bottom_stream_menu'>
		<StreamRecord title={streamTitle} enableAudio={enableAudio}
			setIsPlaying={setIsPlaying} isPlaying={isPlaying}
			setCurrentTab={setCurrentTab} />
		{
			!isPlaying && <React.Fragment>
				<div className='btn' onClick={onAudioHandler}>
					<div className={`icon icon-microphone ${enableAudio ? 'active' : ''}`} />
					<div className='font_azo'>Audio on</div>
				</div>
				<div className='btn' onClick={onCameraHandler}>
					<div className={`icon icon-video-camera ${enableCamera ? 'active' : ''}`} />
					<div className='font_azo'>Camera off</div>
				</div>
			</React.Fragment>
		}
		<div className='btn' onClick={() => onScale(-2)}>
			<div className='icon icon-zoom-out-btn' />
			<div className='font_azo'>Zoom out</div>
		</div>
		<div className='btn' onClick={() => onScale(2)}>
			<div className='icon icon-zoom-in-btn' />
			<div className='font_azo'>Zoom in</div>
		</div>
		<div onClick={onFullScreenHandler} className='fullscreen-btn btn'>
			<div className='icon icon-fullscreen' />
			<div className='font_azo'>Full screen</div>
		</div>
	</div>
}
