import React, { useEffect, useState, useRef } from 'react';
import useCanvas from '../useCanvas';
import lottie from 'lottie-web';
import StreamPlay from './StreamPlay';
import loading_bar from '../../../assets/lotties/loading_model.json';

const StreamCanvas = ({
  streamId,
  avatar,
  background,
  showTitle,
  enableCamera,
  title,
  isModelLoaded,
  onFullScreen = () => null,
  onLoadingModel = () => null,
  scale,
}) => {
  const [didMount, setDidMount] = useState(false);
  const videoElement = useRef(null);
  const guideCanvas = useRef(null);
  const live2dRef = useRef(null);
  const container = useRef(null);
  const [avatarScale, setAvatarScale] = useState([]);
  const [fullscreenchange, setFullscreenchange] = useState(false);

  useCanvas({
    didMount,
    videoElement: videoElement.current,
    guideCanvas: guideCanvas.current,
    live2dRef: live2dRef.current,
    container: container.current,
    backgroundImage: background,
    modelUrl: avatar.model,
    showTitle,
    streamTitle: title.toUpperCase(),
    enableCamera: enableCamera,
    initialScale: avatar.initialScale,
    maxScale: avatar.maxScale,
    minScale: avatar.minScale,
    avatarAnchor: avatar.anchor,
    onLoadingModel: onLoadingModel,
    avatarScale: avatarScale,
  });

  useEffect(() => {
    setAvatarScale([...scale])
  }, [scale])

  useEffect(() => {
    setDidMount(true);

    if (!document.querySelector('#loading_bar_container svg')) {
      lottie.loadAnimation({
        container: document.querySelector('#loading_bar_container'),
        animationData: loading_bar
      });
    }

  }, [avatar, background]);

  return <div ref={container} className='stream_cavas'>
    {
      !isModelLoaded &&
      <div className='stream_cavas__loading'>
        <div id='loading_bar_container'></div>
      </div>
    }
    <div className='stream_cavas__content'>
      <video ref={videoElement} className='input_video' width='1280px' height='720px' autoPlay muted playsInline></video>
      <canvas ref={guideCanvas} className='guides'></canvas>
    </div>
    <canvas ref={live2dRef} id='live2d'></canvas>
  </div>;
};

export default StreamCanvas;