import React, { useState, useEffect } from 'react';
import {
  authWithEmailAndPassword,
  createUserWithEmail,
  facebookAuthProvider,
  sendPasswordResetEmail,
  googleAuthProvider
} from '../../services/firebase';
import { startService } from '../../services/heroku';
import { connect } from 'react-redux';
import { getLocals } from '../../utils/locals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import './index.css'

library.add(fab);

const LoginForm = props => {
  const { lang } = props.app;
  const locals = getLocals(lang);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState('signup');
  const [title, setTitle] = useState(locals.create_account);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pwdRequestedSuccess, setPwdRequestedSuccess] = useState(false);

  const onModeChangeHandler = mode => {
    if (mode === 'signup') setTitle(locals.create_account);
    if (mode === 'recovery') setTitle(locals.recover_password);
    if (mode === 'login') setTitle(locals.welecome_back);
    setMode(mode);
    if (typeof props.onModeChange === 'function') props.onModeChange(mode);
  };

  const onCatchError = (error) => {
    if (typeof error === 'string') setError(error);
  };

  const onSubmitHandler = () => {
    setError('');
    if (mode === 'signup') {
      createUserWithEmail(email, password, onCatchError);
    } else {
      authWithEmailAndPassword(email, password, onCatchError);
    }
  };

  const onSendPasswordResetEmail = () => {
    setError('');
    setIsLoading(true);
    sendPasswordResetEmail(email, (error, result) => {
      if (error) return onCatchError(error);
      setPwdRequestedSuccess(true);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (typeof props.onModeChange === 'function') props.onModeChange(mode);
    startService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (mode === 'recovery' && pwdRequestedSuccess) return <div className='login_form'>
    <h2>{locals.password_email_sent}</h2>
  </div>;

  if (mode === 'recovery') return <div className='login_form'>
    <h1 className='auth__title font_kansas'>{title}</h1>
    <div className='login_form__fields'>
      <div className='login_form__field'>
        <label className='main_bg' htmlFor='email'>{locals.email}</label>
        <input id='email' name='email' type='text'
          value={email} onChange={e => setEmail(e.target.value)} />
      </div>
    </div>
    {
      isLoading
        ? <button className='btn login_form__btn'>{locals.loading}...</button>
        : <button className='btn login_form__btn' onClick={onSendPasswordResetEmail}>{locals.change_password}</button>
    }
    <div>
      <span className='error'>{error}</span>
    </div>
  </div>;

  return <div className='login_form'>
    <h1 className='auth__title font_kansas'>{title}</h1>
    <div className='login_form__social'>
      <button onClick={googleAuthProvider}>
        <span className='spacer'><FontAwesomeIcon icon={['fab', 'google']} /></span>
        <span>{locals.login_google}</span>
      </button>
      <button onClick={facebookAuthProvider}>
        <span className='spacer'><FontAwesomeIcon icon={['fab', 'facebook']} /></span>
        <span>{locals.login_facebook}</span>
      </button>
    </div>
    <div className='login_form__divider'>
      <div className='line' />
      <p>{locals.or}</p>
      <div className='line' />
    </div>
    <div className='login_form__fields'>
      <div className='login_form__field'>
        <label className='' htmlFor='email'>{locals.email}</label>
        <input id='email' name='email' type='text'
          value={email} onChange={e => setEmail(e.target.value)} />
      </div>
      <div className='login_form__field'>
        <label className='' htmlFor='password'>{locals.password}</label>
        <input id='password' name='password' type='password'
          value={password} onChange={e => setPassword(e.target.value)} />
      </div>
    </div>
    <div className='login_form__buttons'>
      <button onClick={onSubmitHandler} className='btn login_form__btn'>
        {
          mode === 'signup'
            ? locals.signup
            : locals.login
        }
      </button>
    </div>
    {
      mode === 'login' &&
      <div className='login_form__options'>
        <p>
          {locals.new_to_cartoober} <b className='btn' onClick={() => onModeChangeHandler('signup')}>{locals.signup_link}</b>
          <span className='divider'>/</span>
          <b className='btn' onClick={() => onModeChangeHandler('recovery')}>{locals.forgot_password}</b>
        </p>
      </div>
    }
    {
      mode === 'signup' &&
      <div className='login_form__options'>
        <p>{locals.already_have_account} <b className='btn' onClick={() => onModeChangeHandler('login')}>{locals.login_link}</b></p>
      </div>
    }
    <span className='error'>{error}</span>
  </div>
};

const mapStateToProps = state => ({
  app: state.app
});

export default connect(mapStateToProps)(LoginForm);