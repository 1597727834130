const initialState = {
  lang: 'en',
  localize: 'en-US',
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANG': return { ...state, lang: action.lang };
    case 'SET_LOCALIZE': return { ...state, localize: action.localize };
    case 'SET_APP_STATE': return { ...state, ...action.state };
    default: return state;
  }
};

export default appReducer;