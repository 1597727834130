import React, { useEffect, useState, useContext } from 'react';
import { fbDatabase } from '../../../services/firebase';
import { connect } from 'react-redux';
import { SocketContext } from '../../../providers/SocketProvider';

import recordImg from '../../../assets/images/icons/record.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

const VideoList = ({
  user
}) => {
  const [videos, setVideos] = useState([]);
  const { socket } = useContext(SocketContext);
  useEffect(() => {
    fbDatabase.ref(`users/${user.uid}/videos`).on('value', snapshot => {
      const videos = snapshot.val();
      if (videos) {
        let videoList = Object.keys(videos).map(key => ({
          ...videos[key],
          id: key
        }));
        setVideos(videoList);
      } else {
        setVideos([]);
      }
    });
  }, [user]);

  const onDeleteHandler = video => {
    const { input, output, id } = video;
    if (socket && input && output) {
      socket.emit('delete_video', { input, output });
      socket.on('delete_video_error', err => {
        console.log('delete_video_error', err);
      });
      socket.on('delete_video_success', () => {
        fbDatabase.ref(`users/${user.uid}/videos/${video.id}`).remove();
      });
    }
  }

  if (videos.length === 0) return <div className='video_list center'>
    <p>No tienes videos creados. Graba un video usando el botón Record</p>
    <img src={recordImg} alt='Record' />
  </div>

  return <div className='video_list'>
    <p className='font_azo title'>Mis videos</p>
    <ul id='ul'>
      {
        videos.map(video => {
          let text = video.filename.length > 15 ? `${video.filename.substring(0, 20)}...` : video.filename;
          return (
            <li key={video.url}>
              <a href={video.url} target='_blank' rel='noopener noreferrer' className='video_item_link'>
                <div className='font_azo'>{text}</div>
                <div onClick={(e) => {e.preventDefault()}}>
                  <span onClick={() => {onDeleteHandler(video)}} className='video_close' >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </span>
                </div>
              </a>
            </li>
          )
        })
      }
    </ul>
  </div>;
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(VideoList);
/*

*/