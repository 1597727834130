const lang = {
  en: {
    'password_success': 'Your password has been changed successfully',
    'success_message': 'Message sent successfully',
  },
  es: {
    'password_success': 'Su contraseña ha sido cambiada con éxito',
    'success_message': 'Mensaje enviado con éxito',
  },
};

export default lang;