import React, { useState, useEffect } from 'react';
import ContactForm from '../ContactForm';

import logo from '../../assets/images/cartoober_logo.svg';
import { fbAuth } from '../../services/firebase';
import { getUrl } from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import './index.css';

const Header = ({
  background,
  showBurgerButton = true,
  openForm = false,
  onClick = () => null,
}) => {
  const [isOpen, setIsOpen] = useState(openForm);
  const navigate = useNavigate();

  useEffect(() => setIsOpen(openForm), [openForm]);

  const onLogoutHandler = () => {
    fbAuth.signOut()
      .then(() => {
        navigate(getUrl('home'));
      })
  };

  return <React.Fragment>
    <div className={`cartoober__header login_modal ${showBurgerButton ? '' : 'simple_mode'}`}>
      <img className='logo' src={logo} alt='Logo cartoober' />
      <span className='icon icon-burger btn burguer_menu'
        onClick={() => { setIsOpen(!isOpen); onClick(!isOpen); }} />
      <ContactForm visible={isOpen} background={background} onClose={() => { setIsOpen(false); onClick(false); }} />
    </div>
    <div className='header_menu'>
      <div className='font_azo' onClick={() => setIsOpen(!isOpen)} >Give us feedback!</div>
      <div className='font_azo'>Contact</div>
      <div onClick={onLogoutHandler}>
        <div className="icon icon-sign-out" />
        <div className='font_azo'>Logout</div>
      </div>
    </div>
  </React.Fragment>;
};

export default Header;  