const fileExtension = {
  'webm': 'webm',
  'mp4': 'mp4',
  'ogg': 'ogg',
  'x-matroska': 'mkv',
};

const videoTypes = ['webm', 'ogg', 'mp4', 'x-matroska'];
const audioTypes = ['webm', 'ogg', 'mp3', 'x-matroska'];
const codecs = ['should-not-be-supported', 'vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus', 'pcm', 'aac', 'mpeg', 'mp4a'];

export const getSupportedMimeTypes = (media = 'video', types = [], codecs = [], withCodes = true) => {
  const isSupported = MediaRecorder.isTypeSupported;
  const supportedTypes = [];
  for (let type of types) {
    const mimeType = `${media}/${type}`;
    if (isSupported(mimeType)) supportedTypes.push({
      mimeType,
      type,
      extension: fileExtension[type],
    });
    if (withCodes) {
      const mimeTypeWithCodecs = [];
      for (let codec of codecs) {
        mimeTypeWithCodecs.push(`${mimeType};codecs=${codec}`);
        mimeTypeWithCodecs.push(`${mimeType};codecs=${codec.toUpperCase()}`);
      }
      for (let mimeTypeWithCodec of mimeTypeWithCodecs)
        if (isSupported(mimeTypeWithCodec)) supportedTypes.push({
          mimeType: mimeTypeWithCodec,
          type,
          extension: fileExtension[type],
        });
    };
  }
  return supportedTypes;
};

export const getSupportedVideoMimeTypes = () => {
  let supported = getSupportedMimeTypes('video', videoTypes, codecs, false);
  let isMp4Supported = Boolean(supported.find(type => type.type === 'mp4'));
  if (!isMp4Supported) supported.push({
    mimeType: 'video/mp4; codecs=mp4a, MP4A',
    type: 'mp4',
    extension: 'mp4',
  });
  return supported;
};

export const getSupportedVideoMimeType = () => {
  return getSupportedMimeTypes('video', videoTypes, codecs)[0];
};

export const getSupportedAudioMimeTypes = () => {
  return getSupportedMimeTypes('audio', audioTypes, codecs);
};

export const getSupportedAudioMimeType = () => {
  return getSupportedMimeTypes('audio', audioTypes, codecs)[0];
};