import React, { useState } from 'react';
import LoginForm from '../../baseComponents/LoginForm';
import Collage from './components/Collage';

import { connect } from 'react-redux';
import { getLocals } from '../../utils/locals';
import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../utils/routes';

import './index.css'

const Auth = props => {
  const { lang } = props.app;
  const locals = getLocals(lang);
  const [mode, setMode] = useState('signup');
  const navigate = useNavigate();
  const onHomeHandler = () => navigate(getUrl('home'));
  const onPrivacyHandler = () => navigate(getUrl('privacy'));
  const onChangeModeHandler = mode => setMode(mode);

  return <div id='auth' className='page'>
    <div className='auth__container'>
      <div className='auth__form'>
        <span onClick={onHomeHandler} className='icon icon-chevron-circle-left home__btn btn' />
        <LoginForm onModeChange={onChangeModeHandler} />
      </div>
      <div className='auth__collage'>
        <Collage mode={mode} />
      </div>
    </div>
    <p className='privacy btn' onClick={onPrivacyHandler}>{locals.privacy_policy}</p>
  </div>;
};

const mapStateToProps = state => ({
  app: state.app
});

export default connect(mapStateToProps)(Auth);