import React, { useContext, useEffect, useRef, useState } from 'react'
import recordImg from '../../../assets/images/icons/record.svg'
import recordImg2 from '../../../assets/images/icons/record2.svg'
import { SocketContext } from '../../../providers/SocketProvider';
import { getSupportedVideoMimeTypes } from '../../../utils/mimeTypes';
import { fbDatabase } from '../../../services/firebase';
import { connect } from 'react-redux';

let recorder = null;

const isSafari = () => navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') == -1 &&
  navigator.userAgent.indexOf('FxiOS') == -1;

const StreamRecord = ({
  title = '',
  mobile,
  user,
  enableAudio,
  setIsPlaying = () => null,
  isPlaying,
  setCurrentTab = () => null,
}) => {
  const { socket } = useContext(SocketContext);
  const [videos, setVideos] = useState([]);
  const [canvas, setCanvas] = useState(null);
  const [stream, setStream] = useState(null);
  const ren = useRef(0)
  const onPlayHandler = async () => {
    let mimeTypeSupported = getSupportedVideoMimeTypes();
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(audioStream => {
          let stream = canvas.captureStream(30);
          if (enableAudio) stream.addTrack(audioStream.getAudioTracks()[0]);
          setStream(stream);
          recorder = new MediaRecorder(stream);
          recorder.ondataavailable = e => {
            if (socket && e.data.size > 0) {
              socket.emit('video_chunk', e.data);
            }
          };
          recorder.start(500);
        })
        .catch(err => {
          console.log('err: ', err);
        });
    } else {
      recorder.onstop = e => {
        document.getElementById('video_loader').classList.add('active');
        console.log('recorder stopped', videos.length);
        const date = new Date();
        const filename = `${title}_${date.getTime()}`;
        socket.emit('convert_video', {
          rotate: isSafari() ? 'angle=180:hflip=1' : 'angle=0',
          filename,
          mimeType: mimeTypeSupported.mimeType,
          extension: mimeTypeSupported.extension
        });
      };
      recorder.stop();
    };
  };

  useEffect(() => {
  }, [enableAudio, stream]);

  useEffect(() => {
    setCanvas(document.querySelector('#live2d'));
  }, [])

  useEffect(() => {
    fbDatabase.ref(`users/${user.uid}/videos`).on('value', snapshot => {
      let videos = snapshot.val();
      if (videos) {
        videos = Object.values(videos).sort((a, b) => a.createdAt - b.createdAt);
        setVideos(videos);
        if (ren.current) setCurrentTab();
        ren.current++;
      }
    });
  }, [user]);

  useEffect(() => {
    if (socket) {
      socket.on('converted_video', ({ url, error, filename, input, output }) => {
        socket.on('converting_video', progress => { console.log('progress', progress); });
        document.getElementById('video_loader').classList.remove('active');
        if (error) return alert('imposible generar video');
        socket.emit('clean_video', url);
        fbDatabase.ref(`users/${user.uid}/videos/${filename}`).set({
          input,
          output,
          url,
          filename,
          createdAt: Date.now(),
        })
      });
    }
  }, [socket]);

  return (
    <>
      {mobile
        ? <div className='btn record'>
          <div onClick={onPlayHandler} className={'mobile record' + (isPlaying ? ' recording' : '')}>
            <img src={!isPlaying ? recordImg : recordImg2} alt='Record' />
          </div>
        </div>
        : <div className='btn record'>
          <div onClick={onPlayHandler} className={'record' + (isPlaying ? ' recording' : '')}>
            <img src={!isPlaying ? recordImg : recordImg2} alt='Record' />
          </div>
          <div onClick={onPlayHandler} className='font_azo'>{isPlaying ? 'Record' : 'Record'}</div>
        </div>}
      <div id='video_loader' className={mobile ? 'mobile' : ''}>
        Generating video...
      </div>
    </>
  )
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(StreamRecord);