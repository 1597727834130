const lang = {
  en: {
    'hero_title_1': `Stream as an <span class='red_color'>animated</span>`,
    'hero_title_2': `avatar from your browser`,
  },
  es: {
    'hero_title_1': `Transmite como un avatar <span class='red_color'>animado</span>`,
    'hero_title_2': `desde tu navegador`,
  },
};

export default lang;