import PrivacyComponent from '../../baseComponents/Privacy';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
  const navigate = useNavigate();
  const onHomeHandler = () => navigate(-1);

  return <div className='contact_form page'>
    <PrivacyComponent onClose={onHomeHandler} />
  </div>
};

export default Privacy;