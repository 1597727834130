import React, { useState, useEffect, useRef } from 'react';
import Avatares from './components/Avatares';
import Backgrounds from './components/Backgrounds';
import Loader from '../../baseComponents/Loader';
import Socials from '../../baseComponents/Socials';

import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUrl } from '../../utils/routes';
import { getAvatares } from '../../services/avatares';
import { getBackgrounds } from '../../services/backgrounds';
import { getLocals } from '../../utils/locals';
import { wait, loadImage } from '../../utils/asyncs';
import { fbDatabase } from '../../services/firebase';
import generateUUID from '../../utils/UUID';

import logo from '../../assets/images/cartoober_logo.svg';
import chapis from '../../assets/images/icons/chapis.svg';
import star1 from '../../assets/images/stars/star_1.svg';
import star2 from '../../assets/images/stars/star_2.svg';
import star3 from '../../assets/images/stars/star_3.svg';
import './index.css';

const NewStream = props => {
  const { lang } = props.app;
  const locals = getLocals(lang);
  const [title, setTitle] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatares, setAvatares] = useState([]);
  const [background, setBackground] = useState(null);
  const [backgrounds, setBackgrounds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [prevBackground, setPrevBackground] = useState(null);
  const backgroundRef = useRef(null);

  const navigate = useNavigate();
  const streamId = generateUUID();

  const onPrivacyHandler = () => navigate(getUrl('privacy'));
  const createStreamHandler = () => {
    const { uid } = props.user;
    const streamRef = fbDatabase.ref(`users/${uid}/streams/${streamId}`);
    streamRef.set({
      title,
      avatar,
      background,
      streamId,
      status: 'created',
      date: Date.now(),
    })
      .then(() => {
        navigate(`${getUrl('stream')}/${streamId}`);
      });
  };

  let btnClass = (title.trim().length >= 1 && background && avatar) ? 'btn_primary' : 'btn_disabled';

  const onKeyDown = e => {
    if (e.key === 'Enter') e.target.blur();
  };

  const onBackgroundChange = background => {
    if (backgroundRef.current)
      backgroundRef.current.classList.add('blur');
    setBackground(background);
    loadImage(background.background)
      .then(() => wait(0.8))
      .then(() => {
        if (backgroundRef.current)
          backgroundRef.current.classList.remove('blur');
        setPrevBackground(background);
      })
      .catch(err => {
        console.log('loadImage rejected', err);
      });
  };

  const loadResources = async () => {
    const avatares = await getAvatares();
    const backgrounds = await getBackgrounds();
    let avatar = avatares[0];
    setAvatares(avatares);
    setBackgrounds([avatar, ...backgrounds]);
    setIsLoading(false);
  };

  useEffect(() => {
    loadResources();
  }, []);


  if (isLoading) return <Loader />;

  let styles = background && background.background ? { backgroundImage: `url(${background.background})` } : {};

  return <div
    id='new_stream'
    className='page'
    style={styles}
  >
    {
      prevBackground && prevBackground.background &&
      <div
        ref={backgroundRef}
        id='new_stream__blur'
        style={{ backgroundImage: `url(${prevBackground.background})` }}
      />
    }
    <div className='new_stream__container'>
      <div className='new_stream__container__header'>
        <div className='spacer'>
          <img className='logo' src={logo} alt='Logo cartoober' />
        </div>
      </div>

      <div className='new_stream__content_main'>
        <h1 className='title font_azo'>{locals.new_stream}</h1>
        <div className='new_stream__content login_modal'>
          <img src={star1} alt='Star 1' className='star left' />
          <div className='new_stream__input'>
            <label className='font_azo'>{locals.title_input_stream}</label>
            <input
              className='font_italic'
              maxLength={60}
              value={title}
              onChange={e => setTitle(e.target.value)}
              type='text'
              placeholder={locals.title_placeholder_stream}
              onKeyDown={onKeyDown} />
          </div>
        </div>

        <div className='new_stream__content login_modal'>
          <img src={star2} alt='Star 2' className='star right' />
          <Avatares
            avatares={avatares}
            currentAvatar={avatar && avatar.id ? avatar.id : ''}
            onChange={avatar => setAvatar(avatar)}
            title={locals.avatar_title_stream}
          />

        </div>

        <div className='new_stream__content login_modal'>
          <img src={star3} alt='Star 3' className='star left' />
          <Backgrounds
            title={locals.background_title_stream}
            backgrounds={backgrounds}
            avatar={avatar}
            current={Boolean(background) ? background : { id: '' }}
            onChange={onBackgroundChange} />
        </div>


        <div className='new_stream__container_submit font_bold'>
          <span onClick={createStreamHandler} className={`btn  ${btnClass}`}>Stream</span>
        </div>
      </div>

      <div className='new_stream__container__header footer'>
        <div className='spacer' />
        <span className='title btn' onClick={onPrivacyHandler}>{locals.privacy_policy}</span>
        <div className='spacer'>
          <Socials />
        </div>
      </div>
    </div>

    <img className='chapis' alt='cartoober' src={chapis} />
  </div>;
};

const mapStateToProps = state => ({
  app: state.app,
  user: state.user,
});

export default connect(mapStateToProps)(NewStream);