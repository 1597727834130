import { useEffect, useState, useRef } from 'react';
import { isMobile } from '../../utils/devices';
import { wait } from '../../utils/asyncs';
import caradetaza from '../../assets/images/icons/caradetaza.svg'
import FontFaceObserver from 'fontfaceobserver';

const PIXI = window.PIXI;
const Kalidokit = window.Kalidokit;
const FaceMesh = window.FaceMesh;
const drawConnectors = window.drawConnectors;
const FACEMESH_TESSELATION = window.FACEMESH_TESSELATION;
const drawLandmarks = window.drawLandmarks;
const Camera = window.Camera;

const {
  // Application,
  live2d: { Live2DModel },
} = PIXI;

const {
  Face,
  Vector: { lerp },
  Utils: { clamp },
} = Kalidokit;

let currentModel,
  background = 0x000000,
  text,
  textBackground,
  title = '',
  facemesh,
  camera,
  scale =
    window.innerWidth < 480
      ? window.innerWidth / 4000
      : window.innerWidth / 8000,
  xPos,
  yPos,
  xTextPos = 50,
  yTextPos = 87,
  sprite1;

const useCanvas = ({
  showTitle,
  streamTitle,
  didMount,
  videoElement,
  guideCanvas,
  live2dRef,
  container,
  backgroundImage,
  modelUrl,
  enableCamera,
  initialScale,
  maxScale,
  minScale,
  avatarAnchor = { x: 0.5, y: 0.5 },
  onLoadingModel = () => null,
  avatarScale,
}) => {
  const [app, setApp] = useState(null);
  const scaleRef = useRef({ initialScale, maxScale, minScale });
  //background = 0x000000;

  const streamContainer = document.getElementById('stream__live_container');

  const getPosition = (xAxis, yAxis) => {
    const streamContainer = document.getElementById('stream__live_container');
    if (!streamContainer) return { x: 0, y: 0 };
    const x = (xAxis * streamContainer.offsetWidth) / 100;
    const y = (yAxis * streamContainer.offsetHeight) / 100;
    return { x, y };
  };

  const getAxis = (x, y) => {
    const streamContainer = document.getElementById('stream__live_container');
    if (!streamContainer) return { x: 0, y: 0 };
    const xPercent = (x * 100) / streamContainer.offsetWidth;
    const yPercent = (y * 100) / streamContainer.offsetHeight;
    xPos = xPercent;
    yPos = yPercent;
    return { xPercent, yPercent };
  };

  const resizeCanvas = async () => {
    console.log('resize')
    if (isMobile()) return;
    setTimeout(() => {
      streamContainer.style.width = (document.querySelector('#root.fullscreen') ? window.innerWidth : 1920) + 'px';
      streamContainer.style.height = (document.querySelector('#root.fullscreen') ? window.innerHeight : 1080) + 'px';
      setTimeout(()=>{
        const { x, y } = getPosition(xPos, yPos);
        console.log(document.querySelector('#root.fullscreen') ? window.innerHeight : 1080 + 'px')
        if (currentModel) {
          currentModel.position.set(x, y);
          currentModel.scale.set(scale);
          currentModel.anchor.set(avatarAnchor.x, avatarAnchor.y);
        }
        if (background) {
          background.width = 1920 * streamContainer.clientHeight / 1080;
          background.height = streamContainer.clientHeight;
          if (background.width < streamContainer.clientWidth) {
            background.width = streamContainer.clientWidth;
            background.height = 1080 * streamContainer.clientWidth / 1920;
          }
          background.position.set(streamContainer.clientWidth / 2, streamContainer.clientHeight / 2);
        }
        loadText(app);
        app.view.width = document.querySelector('#root.fullscreen') ? window.innerWidth : 1920;
        app.view.height = document.querySelector('#root.fullscreen') ? window.innerHeight : 1080;
        //render();
      }, 1)
    }, 1);
  };
  
  useEffect(() => {
    if (currentModel) {
      onScale( avatarScale[1]);
    }
  }, [avatarScale]);

  useEffect(() => {
    scaleRef.current = { initialScale, maxScale, minScale };
  }, [maxScale, minScale, initialScale]);

  useEffect(() => {
    window.addEventListener('resize', resizeCanvas);
    window.addEventListener('fullscreenchange', resizeCanvas);
    return () => {
      window.removeEventListener('resize', resizeCanvas);
      window.removeEventListener('fullscreenchange', resizeCanvas);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  useEffect(() => {
    title = streamTitle;
    if (app && textBackground && text) {
      if (isMobile()) {
        if (textBackground) app.stage.removeChild(textBackground);
        if (text) app.stage.removeChild(text);
        if (sprite1) app.stage.removeChild(sprite1);
        return wait(1).then(_ => loadText(app));
      }
      loadText(app);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamTitle])

  useEffect(() => {
    if (app && currentModel) loadModel(app);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelUrl]);

  useEffect(() => {
    if (app && textBackground && text) {
      if (showTitle) {
        app.stage.addChild(textBackground);
        app.stage.addChild(text);
      } else {
        app.stage.removeChild(textBackground);
        app.stage.removeChild(text);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTitle, app]);

  useEffect(() => {
    if (app) loadBackground(app)
      .then(() => {
        if (currentModel) {
          app.stage.removeChild(currentModel);
          app.stage.addChild(currentModel);
        }
        if (textBackground && text) {
          app.stage.removeChild(textBackground);
          app.stage.removeChild(text);
          app.stage.addChild(textBackground);
          app.stage.addChild(text);
          if (sprite1) {
            app.stage.removeChild(sprite1);
            app.stage.addChild(sprite1);
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundImage, app]);

  useEffect(() => {
    if (enableCamera && camera) return camera.start();
    else if (!enableCamera && camera) return camera.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableCamera, camera]);

  useEffect(() => {
    title = streamTitle;
    if (didMount) main();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didMount]);

  useEffect(() => {
    return () => {
      if (app) {
        app.stage.removeChild(currentModel);
        app.destroy();
      }
      if (currentModel && currentModel.destroy) {
        currentModel.destroy();
      }
      if (camera) camera.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadModel = async (app) => {
    if (currentModel) app.stage.removeChild(currentModel);
    onLoadingModel(false);

    currentModel = await Live2DModel.from(modelUrl, { autoInteract: false });
    onLoadingModel(true);
    currentModel.scale.set(initialScale);
    scale = initialScale;
    currentModel.interactive = true;
    currentModel.anchor.set(avatarAnchor.x, avatarAnchor.y);
    const { x, y } = getPosition(50, 50);
    xPos = 50;
    yPos = 50;
    currentModel.position.set(x, y);
    currentModel.on('pointerdown', (e) => {
      const { xPercent, yPercent } = getAxis(e.data.global.x, e.data.global.y);
      xPos = xPercent;
      yPos = yPercent;
      currentModel.offsetX = e.data.global.x - currentModel.position.x;
      currentModel.offsetY = e.data.global.y - currentModel.position.y;
      currentModel.dragging = true;
    });
    currentModel.on('pointerup', (e) => {
      currentModel.dragging = false;
    });
    currentModel.on('pointermove', (e) => {
      if (currentModel.dragging) {
        const { xPercent, yPercent } = getAxis(
          e.data.global.x,
          e.data.global.y
        );
        xPos = xPercent;
        yPos = yPercent;
        currentModel.position.set(
          e.data.global.x - currentModel.offsetX,
          e.data.global.y - currentModel.offsetY
        );
      }
    });
    currentModel.zIndex = 10;
    app.stage.addChild(currentModel);
    //loadText(app)
    return currentModel;
  };

  const loadBackground = async (app) => {
    const backgroundTexture = await PIXI.Texture.from(backgroundImage);
    background = new PIXI.Sprite(backgroundTexture);
    background.width = 1920 * app.screen.height / 1080;
    background.height = app.screen.height;
    if (background.width < app.screen.width) {
      background.width = app.screen.width;
      background.height = 1080 * app.screen.width / 1920;
    }
    background.anchor.x = 0.5
    background.anchor.y = 0.5
    background.position.set(app.screen.width / 2, app.screen.height / 2);
    app.stage.addChild(background);
    return background;
  };

  const loadText = async (app) => {

    var font = new FontFaceObserver('azo-sans-uber');

    font.load().then(function () {
      if (textBackground) app.stage.removeChild(textBackground);
    if (text) app.stage.removeChild(text);
    text = new PIXI.Text(title, {
      fontFamily: 'azo-sans-uber',
      fontSize: Math.max(20, window.innerWidth / 30),
      fill: 'black',
      align: app.screen.width > 768 ? 'center' : 'left',
      wordWrap: true,
      wordWrapWidth: app.screen.width * 0.7,
      breakWords: true
    });
    let { x, y } = getPosition(xTextPos, yTextPos);
    x = app.screen.width < 768 ? x - (text.width / 2) : app.screen.width / 25;
    y = y - (text.height / 5);
    text.position.set(app.screen.width < 768 ? x : x + 170, y);

    textBackground = new PIXI.Graphics();
    textBackground.lineStyle(2.5, 0x000000, 1)
    textBackground.beginFill(0xEBEBEB, streamTitle ? 1 : 0); // Blue
    textBackground.drawRoundedRect(x - 25, y - 30, text.width + (app.screen.width < 768 ? 50 : 250), text.height + 60, 30);
    textBackground.endFill();

    if (title) {
      app.stage.addChild(textBackground);
      app.stage.addChild(text);
      if (sprite1) {
        app.stage.addChild(sprite1);
        sprite1.scale.set(app.screen.width < 768 ? 0 : 0.8);
        sprite1.position.set(x + 5, y + text.height / 2);
      }
    }

    
    const loader = PIXI.loader;

    loader.add('image1', caradetaza);

    loader.on('complete', function (loader, resources) {
      sprite1 = new PIXI.Sprite(loader.resources.image1.texture);
      sprite1.scale.set(app.screen.width < 768 ? 0 : 0.8);
      sprite1.position.set(x + 15, y + text.height / 2);
      sprite1.anchor.set(0, 0.5)
      app.stage.addChild(sprite1);
    });

    loader.load();
    });
  };

  const main = async () => {
    try {
      const app = new PIXI.Application({
        view: live2dRef,
        autoStart: true,
        transparent: false,
        resizeTo: streamContainer,
        antialias: true
      });
      setApp(app);
      loadBackground(app)
      .then(() => {
        return loadModel(app);
      })
      .then(() => {
        return loadText(app);
      })

      live2dRef.addEventListener('wheel', (e) => {
        e.preventDefault();
        scale = currentModel.scale.x + e.deltaY * -0.001;
        if (scale < scaleRef.current.minScale) scale = scaleRef.current.minScale;
        if (scale > scaleRef.current.maxScale) scale = scaleRef.current.maxScale;
        currentModel.scale.set(clamp(scale, -0.5, 10));
      });

      // create media pipe facemesh instance
      facemesh = new FaceMesh({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
        }
      });

      // set facemesh config
      facemesh.setOptions({
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
      });

      // pass facemesh callback function
      facemesh.onResults(onResults);

      setApp(app);
      initializeCamera(facemesh);
    } catch (error) {
      console.error('main', error);
    }
  };

  const onScale = (e) => {
    if (currentModel) {
      scale = currentModel.scale.x + e * 0.1;
      if (scale < scaleRef.current.minScale) scale = scaleRef.current.minScale;
      if (scale > scaleRef.current.maxScale) scale = scaleRef.current.maxScale;
      currentModel.scale.set(clamp(scale, -0.5, 10));
    }
  }

  const onResults = (results) => {
    drawResults(results.multiFaceLandmarks[0]);
    animateLive2DModel(results.multiFaceLandmarks[0]);
  };

  // draw connectors and landmarks on output canvas
  const drawResults = (points) => {
    if (!guideCanvas || !videoElement || !points) return;
    guideCanvas.width = videoElement.videoWidth;
    guideCanvas.height = videoElement.videoHeight;
    let canvasCtx = guideCanvas.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, guideCanvas.width, guideCanvas.height);
    // Use `Mediapipe` drawing functions
    drawConnectors(canvasCtx, points, FACEMESH_TESSELATION, {
      color: "#C0C0C070",
      lineWidth: 1,
    });
    if (points && points.length === 478) {
      //draw pupils
      drawLandmarks(canvasCtx, [points[468], points[468 + 5]], {
        color: "#ffe603",
        lineWidth: 2,
      });
    }
  };

  const animateLive2DModel = (points) => {
    if (!currentModel || !points) return;

    let riggedFace;

    if (points) {
      // use kalidokit face solver
      riggedFace = Face.solve(points, {
        runtime: 'mediapipe',
        video: videoElement,
      });
      rigFace(riggedFace, 0.5);
    }
  };

  const rigFace = (result, lerpAmount = 0.7) => {
    if (!currentModel || !result) return;
    // const updateFn = currentModel.internalModel.motionManager.update;
    const coreModel = currentModel.internalModel.coreModel;

    currentModel.internalModel.motionManager.update = (...args) => {
      // disable default blink animation
      currentModel.internalModel.eyeBlink = undefined;

      coreModel.setParameterValueById(
        'ParamEyeBallX',
        lerp(
          result.pupil.x,
          coreModel.getParameterValueById('ParamEyeBallX'),
          lerpAmount
        )
      );
      coreModel.setParameterValueById(
        'ParamEyeBallY',
        lerp(
          result.pupil.y,
          coreModel.getParameterValueById('ParamEyeBallY'),
          lerpAmount
        )
      );

      // X and Y axis rotations are swapped for Live2D parameters
      // because it is a 2D system and KalidoKit is a 3D system
      coreModel.setParameterValueById(
        'ParamAngleX',
        lerp(
          result.head.degrees.y,
          coreModel.getParameterValueById('ParamAngleX'),
          lerpAmount
        )
      );
      coreModel.setParameterValueById(
        'ParamAngleY',
        lerp(
          result.head.degrees.x,
          coreModel.getParameterValueById('ParamAngleY'),
          lerpAmount
        )
      );
      coreModel.setParameterValueById(
        'ParamAngleZ',
        lerp(
          result.head.degrees.z,
          coreModel.getParameterValueById('ParamAngleZ'),
          lerpAmount
        )
      );

      // update body params for models without head/body param sync
      const dampener = 0.3;
      coreModel.setParameterValueById(
        'ParamBodyAngleX',
        lerp(
          result.head.degrees.y * dampener,
          coreModel.getParameterValueById('ParamBodyAngleX'),
          lerpAmount
        )
      );
      coreModel.setParameterValueById(
        'ParamBodyAngleY',
        lerp(
          result.head.degrees.x * dampener,
          coreModel.getParameterValueById('ParamBodyAngleY'),
          lerpAmount
        )
      );
      coreModel.setParameterValueById(
        'ParamBodyAngleZ',
        lerp(
          result.head.degrees.z * dampener,
          coreModel.getParameterValueById('ParamBodyAngleZ'),
          lerpAmount
        )
      );

      // Simple example without winking.
      // Interpolate based on old blendshape, then stabilize blink with `Kalidokit` helper function.
      let stabilizedEyes = Kalidokit.Face.stabilizeBlink(
        {
          l: lerp(
            result.eye.l,
            coreModel.getParameterValueById('ParamEyeLOpen'),
            0.7
          ),
          r: lerp(
            result.eye.r,
            coreModel.getParameterValueById('ParamEyeROpen'),
            0.7
          ),
        },
        result.head.y
      );
      // eye blink
      coreModel.setParameterValueById('ParamEyeLOpen', stabilizedEyes.l);
      coreModel.setParameterValueById('ParamEyeROpen', stabilizedEyes.r);

      // mouth
      coreModel.setParameterValueById(
        'ParamMouthOpenY',
        lerp(
          result.mouth.y,
          coreModel.getParameterValueById('ParamMouthOpenY'),
          0.3
        )
      );
      // Adding 0.3 to ParamMouthForm to make default more of a 'smile'
      coreModel.setParameterValueById(
        'ParamMouthForm',
        0.3 +
        lerp(
          result.mouth.x,
          coreModel.getParameterValueById('ParamMouthForm'),
          0.3
        )
      );
    };
  };

  const initializeCamera = (fm = facemesh) => {
    camera = new Camera(videoElement, {
      onFrame: async () => {
        try {
          await facemesh.send({ image: videoElement });
        } catch (error) {
          //console.log('initializeCamera', error);
        }
      },
      width: 640,
      height: 480,
    });
    if (enableCamera) camera.start();
  };
};

export default useCanvas;
