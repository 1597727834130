const backgrounds = {
  'bg-14': {
    id: 'bg-14',
    name: 'Background 14',
    background: '/backgrounds/bg_14.jpg',
    thumb: '/backgrounds/bg_thumb_14.jpg',
  },
  'bg-15': {
    id: 'bg-15',
    name: 'Background 15',
    background: '/backgrounds/bg_15.jpg',
    thumb: '/backgrounds/bg_thumb_15.jpg',
  },
  'bg-1': {
    id: 'bg-1',
    name: 'Background 1',
    background: '/backgrounds/bg_01.jpg',
    thumb: '/backgrounds/bg_thumb_01.jpg',
  },
  'bg-2': {
    id: 'bg-2',
    name: 'Background 2',
    background: '/backgrounds/bg_02.jpg',
    thumb: '/backgrounds/bg_thumb_02.jpg',
  },
  'bg-3': {
    id: 'bg-3',
    name: 'Background 3',
    background: '/backgrounds/bg_03.jpg',
    thumb: '/backgrounds/bg_thumb_03.jpg',
  },
  'bg-4': {
    id: 'bg-4',
    name: 'Background 4',
    background: '/backgrounds/bg_04.jpg',
    thumb: '/backgrounds/bg_thumb_04.jpg',
  },
  'bg-5': {
    id: 'bg-5',
    name: 'Background 5',
    background: '/backgrounds/bg_05.jpg',
    thumb: '/backgrounds/bg_thumb_05.jpg',
  },
  'bg-6': {
    id: 'bg-6',
    name: 'Background 6',
    background: '/backgrounds/bg_06.jpg',
    thumb: '/backgrounds/bg_thumb_06.jpg',
  },
  'bg-7': {
    id: 'bg-7',
    name: 'Background 7',
    background: '/backgrounds/bg_07.jpg',
    thumb: '/backgrounds/bg_thumb_07.jpg',
  },
  'bg-8': {
    id: 'bg-8',
    name: 'Background 8',
    background: '/backgrounds/bg_08.jpg',
    thumb: '/backgrounds/bg_thumb_08.jpg',
  },
  'bg-9': {
    id: 'bg-9',
    name: 'Background 9',
    background: '/backgrounds/bg_00.jpg',
    thumb: '/backgrounds/bg_thumb_00.jpg',
  },
  'bg-10': {
    id: 'bg-10',
    name: 'Background 10',
    background: '/backgrounds/bg_10.jpg',
    thumb: '/backgrounds/bg_thumb_10.jpg',
  },
  'bg-12': {
    id: 'bg-12',
    name: 'Background 12',
    background: '/backgrounds/bg_12.jpg',
    thumb: '/backgrounds/bg_thumb_12.jpg',
  },
  'bg-13': {
    id: 'bg-13',
    name: 'Background 13',
    background: '/backgrounds/bg_13.jpg',
    thumb: '/backgrounds/bg_thumb_13.jpg',
  }
};

export const getBackgrounds = () => {
  let response = Object.values(backgrounds);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(response);
    }, 900);
  });
};

export const getBackground = id => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(backgrounds[id]);
    }, 900);
  });
}