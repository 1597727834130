import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);

const Socials = () => {
  return <div className='footer__social_container'>
    <a href='https://twitter.com/cartoober' target='_blank' rel='noreferrer'><span><FontAwesomeIcon icon={['fab', 'twitter']} /></span></a>
    <a href='https://www.instagram.com/cartoober.stream/' target='_blank' rel='noreferrer'><span><FontAwesomeIcon icon={['fab', 'instagram']} /></span></a>
    <a href='https://www.facebook.com/cartoober/' target='_blank' rel='noreferrer'><span><FontAwesomeIcon icon={['fab', 'facebook']} /></span></a>
    <a href='https://www.tiktok.com/@cartoober.stream' target='_blank' rel='noreferrer'><span><FontAwesomeIcon icon={['fab', 'tiktok']} /></span></a>
  </div>;
};

export default Socials;