import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import { getUser } from './heroku';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATA_BASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
});

export const fbDatabase = firebase.database();
export const fbFirestore = firebase.firestore();
export const fbAuth = firebase.auth();
export const updatePassword = (id, password) => {
  return null
};
export const sendPasswordResetEmail = (email, cb) => {
  //eslint-disable-next-line
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(email)) return cb('* El correo electrónico no cumple conn el formato', null);
  return getUser(email)
    .then(user => {
      if (!user.uid) return cb('* El correo electrónico no existe', null);
      return cb(null, user.uid);
    })
    .catch(err => {
      console.log('err', err);
      let error = errorList[err.code]
        ? errorList[err.code]
        : 'Error desconocido'
      cb(error, null);
    });
};
export const authWithEmailAndPassword = (email, password, cb) => {
  //eslint-disable-next-line
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(email)) return cb('* El correo electrónico no cumple conn el formato', null);
  if (password.length < 6) return cb('* La contraseña debe contener al menos 6 caracteres');

  fbAuth.signInWithEmailAndPassword(email, password)
    .then(result => {
      return cb(null, result);
    })
    .catch(err => {
      let error = errorList[err.code]
        ? errorList[err.code]
        : 'Error desconocido'
      cb(error, null);
    });
};
export const createUserWithEmail = (email, password, cb) => {
  //eslint-disable-next-line
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(email)) return cb('* El correo electrónico no cumple con el formato', null);
  if (password.trim().length < 6) return cb('* La contraseña debe contener al menos 6 caracteres');

  fbAuth.createUserWithEmailAndPassword(email, password)
    .then(result => {
      return cb(null, result);
    })
    .catch(err => {
      let error = errorList[err.code]
        ? errorList[err.code]
        : 'Error desconocido'
      cb(error, null);
    });
};
export const facebookAuthProvider = () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.setCustomParameters({ 'display': 'popup' });
  fbAuth.signInWithPopup(provider)
    .catch((error) => {
      console.log('error', error);
    });
};
export const googleAuthProvider = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ 'display': 'popup' });
  fbAuth.signInWithPopup(provider)
    .catch((error) => {
      console.log('error', error);
    });
};

const errorList = {
  'auth/user-not-found': '* Usuario o contraseña incorrectos',
  'auth/wrong-password': '* Usuario o contraseña incorrectos',
  'auth/not-valid-user': '* Este usuario no tiene permisos para acceder',
  'auth/email-already-in-use': '* El correo electrónico ya esta registrado',
  'auth/email-already-exists': '* El correo electrónico ya esta registrado'
};
