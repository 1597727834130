const lang = {
  en: {
    'home_title_1': 'The easiest way',
    'home_title_2': 'to become a Vtuber',
    'home_step_1_2': 'for free',
    'home_step_2_1': '2. Choose your avatar',
    'home_step_2_2': 'and background',
    'home_step_3_1': '3. Stream to your',
    'home_step_3_2': 'favorite caster',
    'home_instruction_btn': 'Stream now',
  },
  es: {
    'home_instruction_title_1': 'La forma más fácil',
    'home_instruction_title_2': 'para convertirse en un Vtuber',
    'home_step_1_2': 'gratis',
    'home_step_2_1': '2. Elige tu avatar',
    'home_step_2_2': 'y el fondo',
    'home_step_3_1': '3. Transmite a tu',
    'home_step_3_2': 'caster favorito',
    'home_instruction_btn': 'Transmitir ahora',
  },
};

export default lang;