import React, { useState, useEffect } from 'react';
import Avatares from './Avatares';
import Backgrounds from './Backgrounds';
import VideList from './VideList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Settings = ({
  locals,
  showTitle,
  title,
  avatares,
  currentAvatar,
  backgrounds,
  currentBackground,
  isModelLoaded,
  onBackgroundChange = () => null,
  onAvatarChange = () => null,
  onShowTitleChange = () => null,
  onTitleChange = () => null,
  visible = false,
  activeElement = window.innerWidth > 769 ? 'background' : null,
  onClose = () => null,
  active,
  setActive,
}) => {

  const [expanded, setExpanded] = useState(true);
  const [dragging, setDragging] = useState(false);
  const [dragLenght, setDragLenght] = useState(0);
  const [lastMousePosition, setLastMousePosition] = useState();
  const [propagation, setPropagation] = useState();

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      e.target.blur();
      onClose();
    }
  };

  const onCloseHandler = () => {
    document.getElementById('root').classList.remove('stream__menu_active');
    onClose();
  };

  const touchStart = (e) => {
    if (active === 'videoList') {
      setLastMousePosition(e.touches[0].clientY);
      setPropagation(!!document.querySelector('.stream__settings_content').scrollTop)
    }
  }

  const touchMove = (e) => {
    if (active === 'videoList') {
      if(!(expanded && (lastMousePosition > e.touches[0].clientY))) {
        setDragging(true);
        if (lastMousePosition) {
          setDragLenght(lastMousePosition - e.touches[0].clientY);
        } else {
          setDragLenght(e.touches[0].clientY)
        }
      }
    }
  }

  const touchEnd = () => {
    if (active === 'videoList') {
      setDragging(false);
      setExpanded(document.querySelector('.stream__settings').clientHeight > (window.innerHeight * (expanded ? 0.6 : 0.3)));
      setLastMousePosition(null);
      setPropagation(true)
    }
  }
  
  useEffect(() => {
    if (activeElement) {
      setExpanded(active === 'videoList')
      setActive(activeElement)
    }
  }, [activeElement]);

  if (!visible) return null;

  return (
    <aside 
      className={`stream__settings login_modal ${expanded && (active === 'videoList') ? 'expanded' : ''} ${dragging ? 'dragging' : ''}`} 
      style={dragging ? {height: `calc(${expanded ? '60vh' : '10.5rem'} + ${dragLenght}px)`} : {}}
      onTouchStart={touchStart}
      onTouchMove={touchMove}
      onTouchEnd={touchEnd}
    >
      <div className='stream__settings_buttons'>
        <div className='stream__settings_close'>
          <span onClick={() => {setExpanded(active === 'videoList'); onCloseHandler()}} className='icon icon-close btn' />
        </div>
        <div className='stream__settings_options'>
          <div>
            <span className={`icon icon-title-btn btn stream__settings_option font_azo ${active === 'title' ? 'active' : ''}`}
              onClick={() => {setExpanded(false); setActive('title')}} />
            <span className={`icon icon-avatar-btn btn stream__settings_option font_azo ${active === 'avatar' ? 'active' : ''}`}
              onClick={() => {setExpanded(false); setActive('avatar')}} />
            <span className={`icon icon-image-btn btn stream__settings_option font_azo ${active === 'background' ? 'active' : ''}`}
              onClick={() => {setExpanded(false); setActive('background')}} />
            <span className={`icon icon-film btn stream__settings_option font_azo ${active === 'videoList' ? 'active' : ''}`}
              onClick={() => {setExpanded(true); setActive('videoList')}} />
          </div>
        </div>
      </div>
      <div 
        className='stream__settings_content' 
        style={dragging ? {height: `calc(calc(${expanded ? '60vh' : '10.5rem'} + ${dragLenght}px) - 4rem)`} : {}}
        onTouchMove={(e)=>{if (propagation) e.stopPropagation()}}
      >
        {
          active === 'title' && <div className='stream__settings_title stream__settings_block font_azo'>
            <input
              onKeyDown={onKeyDown}
              maxLength={60}
              value={title}
              onChange={e => onTitleChange(e.target.value)}
              type='text'
              placeholder={locals.title_placeholder} />
            <span className={`show_title_btn btn icon icon-eye ${showTitle ? 'active' : ''}`} onClick={() => onShowTitleChange(!showTitle)} />
          </div>
        }
        {
          active === 'avatar' && !isModelLoaded && <div className='stream__settings_avatar'>
            <span>Loading model... please wait</span>
          </div>
        }
        {
          isModelLoaded && active === 'avatar' && <div className='stream__settings_avatar'>
            <Avatares
              avatares={avatares}
              currentAvatar={currentAvatar}
              onChange={avatar => onAvatarChange(avatar)}
            />
          </div>
        }
        {
          active === 'background' && <div className='stream__settings_background'>
            <Backgrounds
              backgrounds={backgrounds}
              avatar={currentAvatar}
              current={currentBackground}
              onChange={background => onBackgroundChange(background)}
            />
          </div>
        }
        {
          active === 'videoList' && <div className='stream__settings_videoList'>
            <VideList />
          </div>
        }
      </div>
    </aside>
  );
};

export default Settings;