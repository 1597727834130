import React from 'react';

import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../../utils/routes';
import { getLocals } from '../../../utils/locals';

import image from '../../../assets/images/icons/caradetaza.svg';

const signupOption = (lang, onNewStreamHandler) => {
  switch (lang) {
    case 'en': return <p> 1. Sign up <span className='kansas_semibold pointer' onClick={onNewStreamHandler}>here</span></p>;
    case 'es': return <p> 1. Regístrate <span className='kansas_semibold pointer' onClick={onNewStreamHandler}>aquí</span></p>;
    default: return <p> 1. Sign up <span className='kansas_semibold pointer' onClick={onNewStreamHandler}>here</span></p >;
  }
}

const Instructions = props => {
  const { lang } = props.app;
  const locals = getLocals(lang);
  const navigate = useNavigate();
  const onNewStreamHandler = () => navigate(getUrl('newStream'));

  return <div className='main_section home_instructions'>
    <img className='home_instructions__logo' src={image} alt='icon' />
    <div className='home_instructions__title'>
      <h2>{locals.home_instruction_title_1}</h2>
      <h2>{locals.home_instruction_title_2}</h2>
    </div>
    <div className='home_instructions__container'>
      <div className='home_instructions__instruction'>
        {signupOption(lang, onNewStreamHandler)}
        <p>{locals.home_step_1_2}</p>
      </div>
      <div className='home_instructions__instruction'>
        <p>{locals.home_step_2_1}</p>
        <p>{locals.home_step_2_2}</p>
      </div>
      <div className='home_instructions__instruction'>
        <p>{locals.home_step_3_1}</p>
        <p>{locals.home_step_3_2}</p>
      </div>
    </div>
    <span className='btn' onClick={onNewStreamHandler}>{locals.home_instruction_btn}</span>
  </div>
};

const mapStateToProps = state => ({
  app: state.app,
});

export default connect(mapStateToProps)(Instructions);
