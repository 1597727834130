const lang = {
  en: {
    'empty_name': 'Name is required',
    'empty_email': 'E-mail is required',
    'empty_message': 'Message is required',
    'empty_password': 'Password is required',
    'password_length': 'Password must be at least 6 characters',
    'password_recovery': 'Password recovery',
    'invalid_email': 'Please enter a valid email address.',
    'error_message': 'Error sending message',
  },
  es: {
    'empty_name': 'Nombre es requerido',
    'empty_email': 'Correo electrónico es requerido',
    'empty_message': 'Mensaje es requerido',
    'empty_password': 'Contraseña es requerida',
    'password_length': 'Contraseña debe tener al menos 6 caracteres',
    'password_recovery': 'Recuperar contraseña',
    'invalid_email': 'Por favor ingrese una dirección de correo electrónico válida.',
    'error_message': 'Error al enviar mensaje',
  },
};

export default lang;