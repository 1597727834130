import Socials from '../Socials';

import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../utils/routes';
import { getLocals } from '../../utils/locals';
import { connect } from 'react-redux';

import leftIcon from '../../assets/images/icons/card_cachetes.png';
import rightIcon from '../../assets/images/icons/card_standup.png';
import './index.css';

const Footer = props => {
  const navigate = useNavigate();
  const onPrivacyHandler = () => navigate(getUrl('privacy'));
  const { lang } = props.app;
  const locals = getLocals(lang);

  return <div className='footer main_section'>
    <img className='footer__icon' src={leftIcon} alt='icon' />
    <div>
      <Socials />
      <p className='with_love'>{locals.made_with_love}</p>
      <p className='privacy btn' onClick={onPrivacyHandler}>{locals.privacy_policy}</p>
    </div>
    <img className='footer__icon' src={rightIcon} alt='icon' />
  </div>
};

const mapStateToProps = state => ({
  app: state.app
});

export default connect(mapStateToProps)(Footer);