import { createStore, combineReducers } from 'redux';

import appReducer from './reducers/app';
import userReducer from './reducers/user';

const reducers = combineReducers({
  app: appReducer,
  user: userReducer
});

export default createStore(reducers);