import React, { useEffect, useState, createContext } from 'react';
import Loader from '../baseComponents/Loader';


import store from '../redux/store';
import { setAppState } from '../redux/actions/app';
import { setUserInfo } from '../redux/actions/user';
import { fbAuth, fbDatabase } from '../services/firebase';

const getLanguage = () => {
  let localize = window.navigator.userLanguage || window.navigator.language;
  let lang = localize.split('-')[0];
  const supportedLang = ['en', 'es'].find(l => l === lang);
  if (!supportedLang) {
    lang = 'en';
    localize = 'en-US';
  }
  return { lang, localize };
}


export const AuthContext = createContext();

export const AuthProvider = props => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fbAuth.onAuthStateChanged(user => {
      if (user) {
        const userRef = fbDatabase.ref(`users/${user.uid}`);
        userRef.once('value', snapshot => {
          let payload = { streams: {}, ...getLanguage() };
          let userInfo = snapshot.val();
          if (!userInfo) userRef.set(payload);
          payload = {
            ...payload,
            ...userInfo,
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            providerData: user.providerData,
          };
          store.dispatch(setUserInfo(payload));
          store.dispatch(setAppState({ lang: payload.lang, localize: payload.localize }));

          setUser(user);
          setIsLoading(false);
        });
      } else setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loader />;

  return <AuthContext.Provider value={{ user }}>
    {props.children}
  </AuthContext.Provider>
};
