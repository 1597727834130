import React, { createContext, useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthProvider';
import { io } from 'socket.io-client';

export const SocketContext = createContext();

// https://render.cartoober.com/
// http://192.168.0.10:8081/

export const SocketProvider = props => {
  const [socket, setSocket] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      const socketBase = io('https://render.cartoober.com/', { reconnect: true, transports: ['websocket'], query: { uuid: user.uid } });
      socketBase.on('connected', response => console.log('connected', response));
      setSocket(socketBase);
    }
    return () => {
      if (socket) socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return <SocketContext.Provider value={{ socket }}>
    {props.children}
  </SocketContext.Provider>;
};
