import { useEffect, useRef } from 'react';
import reactDom from 'react-dom';

import './index.css'

const usePortal = (id) => {
  const rootElemRef = useRef(document.createElement('div'));

  useEffect(() => {
    const parentElem = document.getElementById(id);
    rootElemRef.current.classList.add('modal_wrapper');
    parentElem.appendChild(rootElemRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => rootElemRef.current.remove();
  }, [id]);

  return rootElemRef.current;
};


const Modal = props => {
  const target = usePortal('root__modal');
  return reactDom.createPortal(props.children, target);
};

export default Modal;