export const setLang = (lang) => ({
  type: 'SET_LANG',
  lang,
});

export const setLocalize = (localize) => ({
  type: 'SET_LOCALIZE',
  localize,
});

export const setAppState = (state) => ({
  type: 'SET_APP_STATE',
  state,
});