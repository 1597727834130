import React, { useContext } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import Auth from '../../pages/Auth';

const PrivateRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  return Boolean(user)
    ? children
    : <Auth />;
};

export default PrivateRoute;