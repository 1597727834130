import React from 'react';

import micIcon from '../../../assets/images/icons/afromic.svg';
import streamfIcon from '../../../assets/images/icons/stream_star.svg';
import dontIcon from '../../../assets/images/icons/don_forget.svg';
import chapis from '../../../assets/images/icons/chapis.svg';
import standUpIcon from '../../../assets/images/icons/standup.svg';

const Collage = ({
  mode = 'signup'
}) => {
  return <React.Fragment>
    <div className='auth_collage_item_1 item'>
      <div className='auth_collage_image' style={{ backgroundImage: `url(${dontIcon})` }} />
    </div>
    <div className='auth_collage_item_2 item'>
      <div className='auth_collage_image' style={{ backgroundImage: `url(${standUpIcon})` }} />
    </div>
    <div className='auth_collage_item_3 item'>
      <div className='auth_collage_image' style={{ backgroundImage: `url(${streamfIcon})` }} />
    </div>
    <div className='auth_collage_item_4 item'>
      <div className='auth_collage_image' style={{ backgroundImage: `url(${chapis})` }} />
    </div>
    <div className='auth_collage_item_5 item'>
      <div className='auth_collage_image' style={{ backgroundImage: `url(${micIcon})` }} />
    </div>
  </React.Fragment>;
};


export default Collage;