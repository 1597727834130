import { connect } from 'react-redux';
import { getLocals } from '../../utils/locals';

import logo from '../../assets/images/icons/loading.png';
import cartoober from '../../assets/images/cartoober_logo.svg';
import './index.css';

const Loader = props => {
  const { lang } = props.app;
  const locals = getLocals(lang);

  return <div className='page cartoober__loader'>
    <div className='cartoober__loader_content'>
      <img className='cartoober__loader_image' src={logo} alt='loading' />
      <img className='cartoober__loader_logo' src={cartoober} alt='cartoober' />
      <span className='font_azo'>{locals.loading}</span>
    </div>
  </div>
};

const mapStateToProps = state => ({
  app: state.app
});

export default connect(mapStateToProps)(Loader);